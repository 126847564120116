body {
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  border: none;

  -webkit-touch-callout: none !important;
  -webkit-tap-highlight-color: transparent !important;
}

#root {
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  border: none;
}
